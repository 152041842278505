ul,
ol,
p,
h1,
h2,
h3 {
    margin-top: 0;
    margin-bottom: 0
}

:root[data-theme="normal"] {
    --color_zero: #FFFFFF;
    --color_one: #422C6C;
    --color_two: #7E64A9;
    --color_three: #614D86;
    --color_four: #37225E;
    --color_five: #2D1952;
    --color_six: #6F5894;
    --color_seven: #FBBA20;
    --color_eight: #3A275F;
    --color_nine: #745D9E;
    --color_ten: #785B9B;
    --color_eleven: #A1A1C2;
    --color_twelve: #1A1A1A;
    --color_thirteen: #0000001F;
    --color_fourteen: #BFBFBF;
    --color_fifteen: red;
    --color_sixteen: #232135;
    --color_seventeen: #513C78;
    --color_eightteen: #68484E;
    --color_nineteen: #7E64A8;

    --color_bg: var(--color_one);
    --color_scrollbar: var(--color_two);
    --color_top_menu_bg: var(--color_three);
    --color_top_menu_bg_shadow: var(--color_thirteen);
    --color_top_menu_vertical_separator: var(--color_two);


    //BLOG
    --color_blog_category: var(--color_seven);
    --color_blog_date: var(--color_two);
    --color_blog_sep: var(--color_six);
    --color_blog_title: var(--color_zero);

    //LANGUAGE MENU
    --color_language_border: var(--color_two);

    //GIVEAWAY ADMIN
    --color_giveaway_border_line: var(--color_seven);

    //WALLET MENU
    --color_wallet_bg: var(--color_one);
    --color_wallet_border: var(--color_two);

    //USER MENU
    --color_user_bg: var(--color_one);
    --color_user_border: var(--color_two);
    --color_user_admin_row: var(--color_eleven);

    //GENERIC ELEMENT
    --color_input_bg: var(--color_zero);
    --color_input_bg_readonly: var(--color_fourteen);
    --color_input_pagination_direct: var(--color_three);

    //BORDER MENU
    --color_border_menu_bg: var(--color_four);
    --color_border_menu_bg_not_selected: var(--color_four);
    --color_border_menu_bg_selected: var(--color_five);
    --color_border_menu_hover: var(--color_one);
    --color_border_menu_chevron: var(--color_three);
    --color_border_search_bg: var(--color_five);
    --color_border_search_text: var(--color_six);
    --color_border_dark_mode_bg: var(--color_five);
    --color_border_dark_mode_text: var(--color_ten);
    --color_border_dark_mode_button: var(--color_seven);

    //MOBILE BOTTOM MENU
    --color_mobilemenu_bg: var(--color_five);
    --color_mobilemenu_bg2: var(--color_three);
    --color_mobilemenu_text_sel: var(--color_zero);
    --color_mobilemenu_top_bar: var(--color_seven);

    //HOME PAGE
    --color_home_chevron_button: var(--color_seven);
    --color_home_chevron_button_bg: var(--color_three);
    --color_home_round_button: var(--color_three);
    --color_home_round_button_sel: var(--color_seven);
    --color_home_round_button_text: var(--color_seven);
    --color_home_round_button_text_sel: var(--color_twelve);
    --color_home_search_bg: var(--color_four);
    --color_home_search_text: var(--color_six);

    //FOOTER
    --color_footer_bg: var(--color_five);
    --color_footer_button: var(--color_one);
    --color_footer_button_text: var(--color_seven);
    --color_footer_hori_bar: var(--color_four);

    //POPUPS
    --color_popup_bg: var(--color_bg);
    --color_popup_error: var(--color_fifteen);
    --color_popup_border: var(--color_one);

    //LOGIN 
    --color_login_close_x: var(--color_ten);

    //CASHIER
    --color_cashier_note_bg: var(--color_sixteen);
    --color_cashier_bubble_top: var(--color_four);
    --color_cashier_bubble_bottom: var(--color_three);
    --color_cashier_button_history: var(--color_four);

    //BUTTONS
    --color_button_light_bg: var(--color_seven);
    --color_button_light_txt: var(--color_one);
    --color_button_white_bg: var(--color_zero);
    --color_button_white_txt: var(--color_seven);
    --color_button_dark_bg: var(--color_three);
    --color_button_dark_txt: var(--color_seven);

    //RADIO BUTTON        
    --color_radio_sel: var(--color_three);
    --color_radio_no_sel: var(--color_four);
    --color_radio_circle: var(--color_seven);

    //REFER A FRIEND
    --color_raf_dark_bubble: var(--color_eight);
    --color_raf_light_bubble: var(--color_three);

    //CONTEST WINNER
    --color_contest_dark_bubble: var(--color_eight);
    --color_contest_light_bubble: var(--color_three);
    --color_contest_spacer: var(--color_nine);

    //Responsible Gambling
    --color_resp_game_but_bg: var(--color_eleven);
    --color_resp_game_but_text: var(--color_zero);

    //FAQ
    --color_faq_box_bg: var(--color_twelve);
    --color_faq_box_border: var(--color_seven);

    //CONTACT US    
    --color_contact_circle_border: var(--color_seven);

    //LOYALTY
    --color_loyalty_bg: var(--color_one);
    --color_loyalty_gift_bg_1: var(--color_three);
    --color_loyalty_gift_bg_2: var(--color_four);
    --color_loyalty_gift_dot: var(--color_seventeen);
    --color_loyalty_title_fa: var(--color_ten);
    --color_loyalty_progress_bar_bg: var(--color_one);
    --color_loyalty_progress_bar_futur_text: var(--color_one);
    --color_loyalty_progress_bar_past_day_bg: var(--color_one);
    --color_loyalty_progress_bar_future_day_bg: var(--color_eightteen);
    --color_loyalty_shop_availability: var(--color_nineteen);
    --color_loyalty_shop_element_selected: var(--color_three);
    --color_loyalty_shop_element_not_selected: var(--color_four);
    --color_loyalty_how_table_bg: var(--color_eight);
    --color_loyalty_how_table_separator: var(--color_one);



    //LISTS DYNAMIC
    --color_dyn_list_header: var(--color_three);
    --color_dyn_list_row1: var(--color_five);
    --color_dyn_list_row2: var(--color_four);

    //OLD    
    --color_bg_dark: var(--color_five);
    --color_bg_medium: #7E64A9;
    --color_bg_light: var(--color_three);
    --color_yellow: #FBBA20;
    --color_yellow_dark: #A69405;
    --color_white: var(--color_zero);
    --color_dark_gray: var(--color_thirteen);
    --color_myst1: var(--color_four);
    --color_theme_text_sel: var(--color_zero);
    --color_theme_text: #9D8CB9;
    --color_admin_menu: #565A69;
    --color_admin_menu_element: #AEAEE0;
    --color_admin_menu_element_hover: #B9996A;
    --color_footer: var(--color_five);
    --color_search_text: var(--color_six);

    body {
        margin: 0;
        padding: 0;
        color: var(--color_zero);
        font-family: "Montserrat", sans-serif !important;
        background-color: var(--color_bg);
    }

    /*Scrollbar*/
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color_bg_medium);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--color_bg);
    }
}

:root[data-theme="dark"] {

    --color_zero: #FFFFFF;
    --color_one: #1A1A1A;
    --color_two: #333333;
    --color_three: #262626;
    --color_four: #121212;
    --color_five: #0D0D0D;
    --color_six: #BFBFBF;
    --color_seven: #FBBA20;
    --color_eight: #595959;
    --color_nine: #000000; //NOT USED
    --color_ten: #000000;
    --color_eleven: #493812;
    --color_twelve: #202020; //NOT USED
    --color_thirteen: #0000001F;
    --color_fourteen: #404040;
    --color_fifteen: red;
    --color_sixteen: #232135;
    --color_forty: #4D4D4D;
    --color_fortyone: #9C9C9C;



    //TOP ELEMENTS
    --color_bg: var(--color_one);

    //SCROLL BAR
    --color_scrollbar: var(--color_two);

    //TOP MENU
    --color_top_menu_bg: var(--color_three);
    --color_top_menu_bg_shadow: var(--color_thirteen);
    --color_top_menu_vertical_separator: var(--color_eight);

    //LANGUAGE MENU
    --color_language_border: var(--color_zero);

    //GIVEAWAY ADMIN
    --color_giveaway_border_line: var(--color_seven);

    //WALLET MENU
    --color_wallet_bg: var(--color_one);
    --color_wallet_border: var(--color_zero);

    //USER MENU
    --color_user_bg: var(--color_one);
    --color_user_border: var(--color_zero);
    --color_user_admin_row: var(--color_fortyone);

    //GENERIC ELEMENT
    --color_input_bg: var(--color_zero);
    --color_input_bg_readonly: var(--color_six);
    --color_input_pagination_direct: var(--color_eight);

    //BORDER MENU    
    --color_border_menu_hover: var(--color_forty);
    --color_border_menu_bg: var(--color_three);
    --color_border_menu_bg_not_selected: var(--color_three);
    --color_border_menu_bg_selected: var(--color_two);
    --color_border_menu_chevron: var(--color_forty);
    --color_border_search_bg: var(--color_two);
    --color_border_search_text: var(--color_six);
    --color_border_dark_mode_bg: var(--color_two);
    --color_border_dark_mode_text: var(--color_seven);
    --color_border_dark_mode_button: var(--color_seven);

    //MOBILE BOTTOM MENU
    --color_mobilemenu_bg: var(--color_five);
    --color_mobilemenu_bg2: var(--color_three);
    --color_mobilemenu_text_sel: var(--color_zero);
    --color_mobilemenu_top_bar: var(--color_seven);

    //HOME PAGE
    --color_home_chevron_button: var(--color_seven);
    --color_home_chevron_button_bg: var(--color_two);
    --color_home_round_button: var(--color_two);
    --color_home_round_button_sel: var(--color_seven);
    --color_home_round_button_text: var(--color_seven);
    --color_home_round_button_text_sel: var(--color_one);
    --color_home_search_bg: var(--color_two);
    --color_home_search_text: var(--color_six);

    //FOOTER
    --color_footer_bg: var(--color_five);
    --color_footer_button: var(--color_three);
    --color_footer_button_text: var(--color_seven);
    --color_footer_hori_bar: var(--color_three);

    //POPUPS
    --color_popup_bg: var(--color_bg);
    --color_popup_error: var(--color_fifteen);
    --color_popup_border: var(--color_zero);

    //LOGIN 
    --color_login_close_x: var(--color_seven);

    //CASHIER
    --color_cashier_note_bg: var(--color_sixteen);
    --color_cashier_bubble_bottom: var(--color_three);
    --color_cashier_button_history: var(--color_four);
    --color_cashier_bubble_top: var(--color_four);

    //BUTTONS
    --color_button_light_bg: var(--color_seven);
    --color_button_light_txt: var(--color_three);
    --color_button_white_bg: var(--color_zero);
    --color_button_white_txt: var(--color_seven);
    --color_button_dark_bg: var(--color_three);
    --color_button_dark_txt: var(--color_seven);

    //RADIO BUTTON        
    --color_radio_sel: var(--color_two);
    --color_radio_no_sel: var(--color_three);
    --color_radio_circle: var(--color_seven);

    //REFER A FRIEND
    --color_raf_dark_bubble: var(--color_five);
    --color_raf_light_bubble: var(--color_three);

    //CONTEST WINNER
    --color_contest_dark_bubble: var(--color_five);
    --color_contest_light_bubble: var(--color_three);
    --color_contest_spacer: var(--color_seven);

    //Responsible Gambling
    --color_resp_game_but_bg: var(--color_seven);
    --color_resp_game_but_text: var(--color_three);

    //FAQ
    --color_faq_box_bg: var(--color_twelve);
    --color_faq_box_border: var(--color_seven);

    //CONTACT US    
    --color_contact_circle_border: var(--color_seven);

    //LOYALTY
    --color_loyalty_bg: var(--color_one);
    --color_loyalty_gift_bg_1: var(--color_two);
    --color_loyalty_gift_bg_2: var(--color_five);
    --color_loyalty_gift_dot: var(--color_five);
    --color_loyalty_title_fa: var(--color_six);
    --color_loyalty_progress_bar_bg: var(--color_twelve);
    --color_loyalty_progress_bar_futur_text: var(--color_ten);
    --color_loyalty_progress_bar_past_day_bg: var(--color_three);
    --color_loyalty_progress_bar_future_day_bg: var(--color_eleven);
    --color_loyalty_shop_availability: var(--color_fortyone);
    --color_loyalty_shop_element_selected: var(--color_two);
    --color_loyalty_shop_element_not_selected: var(--color_four);
    --color_loyalty_how_table_bg: var(--color_three);
    --color_loyalty_how_table_separator: var(--color_one);

    //LISTS DYNAMIC
    --color_dyn_list_header: var(--color_four);
    --color_dyn_list_row1: var(--color_three);
    --color_dyn_list_row2: var(--color_two);

    //OLD    
    --color_bg_dark: #2D1952;
    --color_bg_medium: #7E64A9;
    --color_bg_light: #614D86;
    --color_yellow: #FBBA20;
    --color_yellow_dark: #A69405;
    --color_white: #FFFFFF;
    --color_dark_gray: #0000001F;
    --color_myst1: #37225E;
    --color_theme_text_sel: #FFFFFF;
    --color_theme_text: #9D8CB9;
    --color_admin_menu: #565A69;
    --color_admin_menu_element: #AEAEE0;
    --color_admin_menu_element_hover: #B9996A;
    --color_footer: #2D1952;
    --color_search_text: #6F5894;

    --old_color_bg: #303030;
    --old_color_bg_dark: #000000;
    --old_color_bg_medium: #6B6B6B;
    --old_color_bg_light: #3D3D3D;
    --old_color_yellow: #A69405;
    --old_color_white: #FFFFFF;
    --old_color_dark_gray: #0000001F;
    --old_color_myst1: #2B2B2B;
    --old_color_theme_text_sel: #FFFFFF;
    --old_color_theme_text: #6B6B6B;
    --old_color-admin_menu: #565A69;
    --old_color-admin_menu_element: #AEAEE0;
    --old_color-admin_menu_element_hover: #B9996A;
    --old_color_footer: #2E2E2E;
    --old_color_search_text: #6E6E6E;

    body {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif !important;
        background-color: var(--color_bg);
    }

    /*Scrollbar*/
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color_scrollbar);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--color_bg);
    }
}

:root[data-theme="affiliates"] {
    --aff_bg: #F4F3F6;
    --color_bg: #F4F3F6;
    --color_bg_dark: #2D1952;
    --color_bg_medium: #7E64A9;
    --color_bg_light: #FFFFFF;
    --color_yellow: #FBBA20;
    --color_yellow_dark: #A69405;
    --color_white: #FFFFFF;
    --color_dark_gray: #0000001F;
    --color_myst1: #37225E;
    --color_theme_text_sel: #FFFFFF;
    --color_theme_text: #9D8CB9;
    --color_admin_menu: #565A69;
    --color_admin_menu_element: #AEAEE0;
    --color_admin_menu_element_hover: #B9996A;
    --color_footer: #2D1952;
    --color_search_text: #6F5894;
    --color_fourteen: #BFBFBF;

    //GENERIC ELEMENT
    --color_input_bg: var(--color_white);
    --color_input_bg_readonly: var(--color_fourteen);

    //BUTTONS
    --color_button_light_bg: var(--color_yellow);
    --color_button_light_txt: var(--color_bg_dark);
    --color_button_white_bg: var(--color_white);
    --color_button_white_txt: var(--color_yellow);
    --color_button_dark_bg: var(--color_bg_dark);
    --color_button_dark_txt: var(--color_yellow);


    body {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif !important;
        background-color: var(--color_bg);
    }


    /*Scrollbar*/
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color_bg_medium);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--color_bg);
    }
}

:root {
    --font_button_bold: normal normal bold 14px/18px Montserrat;
    --font_button_normal: normal normal 300 16px/19px Montserrat;
    --font_button_bold_mobile: normal normal bold 12px/16px Montserrat;
    --font_button_normal_mobile: normal normal 300 14px/17px Montserrat;
    --font_mobile_bottom_menu_bold: normal normal bold 14px/17px Montserrat;

    --font_bold: normal normal bold 16px/19px Montserrat;
    --font_small: normal normal 300 14px/17px Montserrat;
    --font_normal: normal normal 300 16px/19px Montserrat;
    --font_normal_mobile: normal normal 300 12px/15px Montserrat;

    --font_mobile_bottom_menu: normal normal 300 12px/15px Montserrat;

    --font_gamelist_cat: normal normal bold 20px/24px Montserrat;

    --font_1215Medium: normal normal 500 12px/15px Montserrat;

    --zindex_top_menu_lang_menu: 800;
    --zindex_admin_menu: 900;
    --zindex_admin_menu_toggle: 910;
    --zindex_border_menu: 1000;
    --zindex_mobile_menu: 1000;
    --zindex_top_menu: 1000;
    --zindex_border_menu_lang: 1010;
    --zindex_top_menu_user_menu: 1100;

    --zindex_default_modal: 1300;
    --zindex_search_modal: 1200;

    --left_menu_width: 240px;
    --left_menu_space_width: 240px;


    .master_centerize {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .master_centerizeVertically {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }


    .master_shake {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;

        /* When the animation is finished, start again */
        animation-iteration-count: 1;
    }

    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }

        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }

        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }

        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }

        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }

        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }

        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }

        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }

        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }

        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }

        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }

}