.master_page_container {
    padding-top: 64px;
    margin: auto;
    width: 75%;
    margin-bottom: 64px;
}

.blogImage {
    min-width: 600px;
    aspect-ratio: 2 / 1; // A definir ailleur
    margin-top: 15px;
    margin-bottom: 15px;
}

.fileInput {
    display: none;
}

.topMainDiv {
    display: grid;
    grid-template-columns: 100%;
}

.grid50_50 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 16px;
}

.grid100 {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 16px;
}

.case1TopText2 {
    text-align: left;
    font: normal normal bold 48px/58px Montserrat;
    letter-spacing: 0px;
    color: #FBBA20;
    opacity: 1;
    margin-bottom: 16px;
}

.case1TopText5 {
    text-align: left;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #FBBA20;
    opacity: 1;
    margin-top: 16px;
}

.case1TopText3 {
    margin-bottom: 16px;
}

.case1TopText4 {
    margin-top: 16px;
}

.tabGroup {
    margin-top: 8px;
}