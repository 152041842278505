.modal {
    z-index: 1350;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;  
    width: 80%;    
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  
  .topModal_container {
    display: flex;
}

.topModal_left {
    width: 70%;
}

.topModal_right {
    margin: 3% 1% 3% 6%;
    width: 25%;
}

.topModal_theIFrame{
    width: 100%;    
}

.topModal_title {
    display: flex;
    align-items: center;
    height: 60px;

    .topModal_rg-heads {
        width: 100%;
        text-align: start;
        font-size: 42px;
        font-weight: bold;
        color: #fbba20 !important;
    }
}

.topModal_link-invite-generated {
        font-size: 18px;
        text-decoration: underline !important;
        color: #ffffff !important;
        font-weight: bold;
        max-width: 420px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
}

.link:hover,
.link:active,
.link:focus {
    color: rgb(123, 121, 250) !important;
}

.topModal_copy-icon {
    font-size: 20px !important;
    padding-left: 2% !important;
}

.NOTUSEant-row {
    align-items: center !important;
}

.topModal_invite-me-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #3A275F 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 1% 2%;
}

.topModal_invite-me-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 400px;
    align-items: center;
}

.topModal_wrap-it {
    display: flex;
    width: 58%;
    flex-direction: row;
}

.topModal_icon-deco {
    background: #614D86 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 5%;
    margin: 4%;
}

.topModal_group-it {
    background-color: #FBBA20;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
}

.topModal_group-it h6 {
    margin: 0;
}

.topModal_line {
    width: 0px;
    height: 40px;
    border: 1px solid #614D86;
    opacity: 1;
    margin-top: 6px;
    margin-right: 2%;
    margin-left: 1%;
}

.topModal_line-2 {
    width: 0px;
    margin-top: 10px;
    height: 40px;
    border: 1px solid #422C6C;
    margin-left: 3%;
}

.NOTUSE_custom-row {
    max-width: 100% !important;
    justify-content: flex-start !important;
}

.topModal_social_icons {
    width: 46%;
    display: flex;
    justify-content: flex-start;
}

.topModal_head-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topModal_head-2 h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
}

.topModal_sucess-1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1% 2%;
    background: #614D86 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #0000001F;
    border-radius: 12px;
    margin-bottom: 6%;
}

.topModal_sucess-1-2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: space-around;
}

.topModal_sucess-1 .topModal_sucess-1-2 .topModal_element {
    width: 66%;
}

.topModal_spin {
    width: 50px;
    height: 50px;
    opacity: 1;
}


.topModal_textCenterize {
    padding-top: 6px;    
}

.topModal_sucess-1 .topModal_sucess-1-2 .topModal_text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.topModal_sucess-2 {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
}

.topModal_sucess-1 .topModal_sucess-1-2 .topModal_text h6 {
    font-weight: bold;
    color: #fbba20 !important;
}

.topModal_sucess-2 .custom {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.topModal_text-css {
    font-weight: bold;
    font-size: 40px;
    margin: 0 !important;
}

/* style.scss or similar */
.topModal_modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1350;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: calc(100vh - 20px);
    /* Full height */
    //overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    backdrop-filter: blur(5px);
}

.deactivateAll {
    
    cursor: progress;
    display: block;
    position: fixed;
    z-index: 1370;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    
}

.topModal_buttonhtml{
    display: flex;
	justify-content: center;
    margin-top: 16px;
}

.topModal_modal-content {
    background-color: var(--color_popup_bg);
    margin: auto;
    padding: 2%;
    border: 1px solid var(--color_popup_border);   
    border-radius: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    max-height: calc(100vh - 200px);    
    height: calc(100vh - 200px);    
}

.topModal_modal-scroll{
    overflow: auto;        
    height: 100%;
    padding-right: 8px;
}

.topModal_modal-scroll_withclose{
    @extend .topModal_modal-scroll;
    height: calc(100% - 50px);
}

.topModal_close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.topModal_close-btn:hover,
.topModal_close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.topModal_inactive {
  display: none;
}
.topModal_active {
    display: block;
}

.topModal_clickable {
    cursor: pointer;
}

.topModal_games {
    width: 18% !important;
    margin: 1%;
}

.topModal_games img {
    width: 100%;
    border-radius: 16px;
}

.topModal_games_title {
    width: 97% !important;
    margin: 3%;
}

.topModal_chevron {
    padding-top: 0px;
    padding-left: 0px;
    line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.topModal_cancel {
    padding-top: 2px;
    padding-left: 10px;
    line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.topModal_custom-spin-text {
    font-weight: bold;
    color: #fbba20 !important;
}

.topModal_modal-content .topModal_pops {
    display: flex;
    justify-content: space-between;
}

.topModal_mobile-img {
    display: none;
}

.topModal_span {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topModal_mobile-line-2{
    display: none;
}

.result-img {
    width: 75px !important;
}


.topModal_result_grid{
  display: grid;
  grid-template-columns: 90px 100%;
}

.topModal_totalLeft{
    font-weight: bold;
    color: #fbba20 !important;
}

.topModal_result_sub_title {
    font-size: 20px;
    color: #fbba20 !important;
}

.topModal_result_sub_title2 {
    font-size: 20px;
    font-weight: bold;
    color: #fbba20 !important;
}

.topModal_result_title {
    width: 90%;    
    font-size: 36px;
    font-weight: bold;
    color: #fbba20 !important;
}

.topModal_play{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #fbba20 !important;
    cursor: pointer;
}

.topModal_result_prim_div{
    text-align: center;
}

.topModal_result_prim_div1{
    text-align:left;
}


@media (max-width: 990px) {

    .topModal_result_prim_div1{
        text-align: center;
    }

    .topModal_result_sub_title {
        font-size: 14px;
    }
    
    .topModal_result_sub_title2 {
        font-size: 14px;
    }
    
    .topModal_result_title {
        font-size: 20px;
    }

    .topModal_right {
        display: none;
    }

    .topModal_left {
        width: 100%;
    }

    .topModal_head-2 img {
        display: none;
    }

    .topModal_head-2 h3 {
        font-size: 28px;
    }

    .topModal_invite-me-1 {
        background: transparent;
        flex-direction: column;
        padding: 2% 0%;
    }

    .topModal_invite-me-2 {
        background: #3A275F 0% 0% no-repeat padding-box;
        border-radius: 12px !important;
        padding: 4% 2%;
        width: 100%;
        text-align: center;
    }

    .topModal_invite-me-2 a {
        font-size: 20px;
        text-align: center;
    }

    .topModal_invite-me-1 .topModal_wrap-it {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .topModal_group-it {
        width: 50%;
    }

    .topModal_invite-me-2 span {
        width: 100%;
    }

    .topModal_desktop-img {
        display: none;
    }

    .topModal_mobile-img {
        display: block;
        margin-top: 2%;
        margin-left: 7%;
    }

    .topModal_element {
        display: none;
    }

    .topModal_line-2 {
        display: none;
    }
  

    .topModal_sucess-1 {
        flex-direction: column;
        padding: 2% 8%;
    }

    .topModal_sucess-1-2 {
        width: 100%;
    }

    .topModal_sucess-1 .topModal_sucess-1-2 .topModal_text {
        justify-content: space-between
    }

    .topModal_sucess-2 {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between
    }

    .topModal_mobile-line-2 {
        width: 288px;
        height: 0px;
        border: 1px solid #422C6C;
        display: block;
    }

    .topModal_games {
        width: 44% !important;
        margin: 3%;
    }


    .topModal_modal {
        padding-top: 16px;        
    }

    .topModal_modal-content{
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
        width: 90%  !important;        
        max-height: calc(100vh - 100px);    
        height: calc(100vh - 100px);       
    }

    .topModal_modal-content h4{
        font-size: 25px!important;
        margin: 0;
    }

    .topModal_modal-content p{
        font-size: 11px;
    }

    .topModal_result_grid{
        display: grid;
        grid-template-columns: 100%;
      }
      
}

@media (max-width: 430px) {
    .topModal_modal-content {
        background-color: var(--color_popup_bg);
        margin: auto;
        padding: 10px;        
        border-radius: 16px;        
    }
}

